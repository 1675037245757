<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1"
    >
        <template v-slot:top>
            <v-toolbar
                flat
            >
                <v-toolbar-title>Pessoas</v-toolbar-title>
                
                <v-alert
                    v-model="alert.show"
                    dismissible
                    :color="alert.type"
                    border="left"
                    elevation="2"
                    colored-border
                    transition="scale-transition"
                >
                    {{alert.text}}
                </v-alert>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>

                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    hide-details
                ></v-text-field>

                <v-divider
                    class="mx-2"
                    inset
                    vertical
                ></v-divider>
                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    dark
                    fab
                    small
                    :to="{name: 'people.create'}"
                >
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                class="mr-2"
                color="indigo darken-1"
                dark
                fab
                small

                :to="{name: 'people.read.one', params: {id: item.id}}"
            >
                <v-icon
                >
                    mdi-pencil
                </v-icon>
            </v-btn>
            <v-dialog
                v-model="dialog"
                width="350px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mr-2"
                        color="pink darken-1"
                        dark
                        fab
                        small
                        
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        Excluir registro?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn
                            @click.prevent="dialog = false"
                        >
                            Sair
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                            @click.prevent="edit(item.id)"
                        >
                            Confirmar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-data-table>
</template>
<script>
export default {
    name: 'vwpeopleRead',
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Nome',
                value: 'nome'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
            items: [],
            search: '',
            overlay: false,
            dialog: false,
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            }
        }
    },
    methods: {
        getData: function(){
            this.overlay = true;
            fetch(process.env.VUE_APP_API + '/peoples/read', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(response => (response.json()))
                .then(resJSON => {
                    if(resJSON.data){
                        this.items = resJSON.response[0].listhelper
                    }
                })
                .catch(error => {
                    console.error('Exception', '=>', error)
                    this.alert = {
                        text: 'Erro interno ao listar o registro de pessoas!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        edit: function(id){
            // this.overlay = true;
            fetch(process.env.VUE_APP_API + '/peoples/delete/' +  id, {
                method: 'DELETE',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(response => (response.json()))
                .then(resJSON => {
                    if(resJSON.data){
                        this.getData()
                    }
                })
                .catch(error => {
                    console.error('Exception', '=>', error)
                })
                .finally(() => {
                    // this.overlay = false
                    this.dialog = false
                })
        }
    },

    mounted(){
        this.getData()
    }
}
</script>